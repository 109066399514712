import meme1 from "../../../media/outro_cuteness/meme1.jpg";
import meme2 from "../../../media/outro_cuteness/meme2.jpg";
import meme3 from "../../../media/outro_cuteness/meme3.jpg";
import meme4 from "../../../media/outro_cuteness/meme4.jpg";
import meme5 from "../../../media/outro_cuteness/meme5.jpg";
import meme6 from "../../../media/outro_cuteness/meme6.jpg";
import meme7 from "../../../media/outro_cuteness/meme7.jpg";
import meme8 from "../../../media/outro_cuteness/meme8.jpg";
import meme9 from "../../../media/outro_cuteness/meme9.jpg";

export const outroMemeArray = [
    meme1,
    meme2,
    meme3,
    meme4,
    meme5,
    meme6,
    meme7,
    meme8,
    meme9,
]
